import { Injectable } from '@angular/core';
import { Observable, map, take, delay, of, switchMap, catchError, throwError,finalize, skipWhile, from  } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler,  HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  RequestStart,
  RequestEnd,
  LogOut,
  ShowToastr,
  SetServiceStatus,
  HideSpinner,
} from '../../app.actions';
import { OktaService } from '../services/okta/okta.service';
import {
  commonErrorHandlingDisabled,
  globalLoadingDisabled,
  ServiceInteractionError
} from '../services/abstract/abstract.server.interaction.service';
import {
  ServiceStatusEnum,
  ToastrTypesEnum
} from '../../constants';
import {
  EmployeeIdClaimError,
  RefreshAccessToken
} from '../../common/tasks/tasks.actions';
import { getIsNoMoreAttempts } from '../../common/tasks/tasks.reducer';

interface IInterceptionOptions {
  isGlobalSpinner: boolean;
  isCommonError: boolean;
  isCached?: boolean;
}

export class TokenClaimError {}

@Injectable()
export class HttpService implements HttpInterceptor {
  constructor( protected store: Store<any>,
              protected oktaService: OktaService) {
  }
  public static extractData(res: HttpResponse<any>): any {
    try {
      return res.body;
    } catch (err) {
      return true;
    }
  }
  private getInterceptionOptions(
    req: HttpRequest<any>,
    isCached?: boolean,
    interceptedOptions?: IInterceptionOptions | null): IInterceptionOptions {
    return {
      isGlobalSpinner: !req.headers.has(globalLoadingDisabled),
      isCommonError: !req.headers.has(commonErrorHandlingDisabled),
      isCached,
      ...interceptedOptions
    };
  }

  private async modifyRequestWithHeaders(req: HttpRequest<any>) {
    const {
      accessToken = null,
      tokenType = 'Bearer'
    } = await this.oktaService.getAccessToken() || {};

    let headers = req.headers;
    
    headers = headers.delete(globalLoadingDisabled);
    headers = headers.delete(commonErrorHandlingDisabled);

    headers = headers.set('Cache-Control', 'no-cache');
    headers = headers.set('Pragma', 'no-cache');
    headers = headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

    if (accessToken) {
      headers = headers.set('Authorization', `${tokenType} ${accessToken}`);
    }

    const authReq = req.clone({
      headers: headers
    });

    return authReq;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.request(next, req, null, false, null)).pipe(switchMap(innerObservable => innerObservable));
  }


  private handleLoginError(wind = window) {
    wind.location.href = '/error/login';

    return of(new ServiceInteractionError());
  }

  private handleUnauthorizedError() {
    this.store.dispatch(new LogOut());

    return of(new ServiceInteractionError());
  }

  private handleSystemOutage() {
    this.store.dispatch(new SetServiceStatus(ServiceStatusEnum.unavailable));

    return of(new ServiceInteractionError());
  }

  private handleServiceErrorWithMessage(msg?: string) {
    this.store.dispatch(new ShowToastr({
      type: ToastrTypesEnum.error,
      message: msg ? msg : 'Can\'t process the request. Please try again later.'
    }));

    return of(new ServiceInteractionError());
  }

  private handleClaimError(next: HttpHandler, cachedReq: any) {
    return (error: any) => error instanceof TokenClaimError
      ? this.store.select(getIsNoMoreAttempts)
        .pipe(
         take(1)
        ,delay(3000)
        ,switchMap((isNoMoreAttempts: any) => {
            if (isNoMoreAttempts) {
              this.store.dispatch(new EmployeeIdClaimError());
              return throwError(() => new TokenClaimError());
            }

            return this.intercept(cachedReq.clone(), next).pipe(delay(3000));
          }
        ))
      : throwError(() => error);
  }

   interceptError(request: HttpRequest<unknown>, next: HttpHandler, isCommonError: boolean): Observable<any> {
    return next.handle(request).pipe(
       catchError(
        (err: HttpErrorResponse) => {
          const { status } = err;

          if (status === 403) {
            return this.handleLoginError();
          }

          if (status === 401) {
            return this.handleUnauthorizedError();
          }

          if (status === 503) {
            return this.handleSystemOutage();
          }

          if (status === 449) {
            this.store.dispatch(new RefreshAccessToken());
            return throwError((() => new TokenClaimError()));
          }

          if (status >= 500) {
            return this.handleServiceErrorWithMessage();
          }

          if (isCommonError && status >= 400) {
            return this.handleServiceErrorWithMessage(err.message);
          }

          return throwError(() => err);
        }
      )
      ,skipWhile((res: any) => {
        const isSkipNeeded = res instanceof ServiceInteractionError;

        if (isSkipNeeded) {
          this.store.dispatch(new HideSpinner());
        }

        return isSkipNeeded;
      }));
  }

   async request(
    next: HttpHandler,
    newRequest: HttpRequest<any>,
    options: IInterceptionOptions | null,
    isCached2: boolean,
    interceptedOptions: IInterceptionOptions | null
  ): Promise<Observable<HttpEvent<any>>> {
    const interceptionOptions = this.getInterceptionOptions(newRequest, isCached2, interceptedOptions);
    const request = await this.modifyRequestWithHeaders(newRequest);

    const { isCommonError, isGlobalSpinner, isCached} = interceptionOptions;

    if (isGlobalSpinner && !isCached) {
      this.store.dispatch(new RequestStart());
    }
    return this.interceptError(request,next, isCommonError).pipe(
      map((response: any) => {
        this.store.dispatch(new SetServiceStatus(ServiceStatusEnum.success));
        return response;
      }),
      catchError(this.handleClaimError(next, request)),
      finalize(() => {
        if (isGlobalSpinner && !isCached) {
          this.store.dispatch(new RequestEnd());
        }
      })
    );
  }
}
